
import Vue from "vue";
export default Vue.extend({
  name: "LeftMenu",

  props: {
    icon: String,
    block: Boolean,
    to: String,
    href: String,
    active: Boolean,
    counter: Number,
    updateCounter: Number,
    small: Boolean,
  },

  methods: {
    onClick() {
      if (this.to) return this.$router.push(this.to);
      if (this.href) return window.open(this.href, "_blank");
      this.$emit("click");
    },
  },
});
