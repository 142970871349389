import { io } from "socket.io-client";

const socket = io(
  process.env.VUE_APP_API_URL.replace("http://", "ws://").replace(
    "https://",
    "wss://"
  ),
  {
    transports: ["websocket"],
    // auth: {
    //   token: (store.state?.user as { _id: string | null | undefined })
    //     ?._id as string,
    // },
    reconnection: true,
    reconnectionDelay: 500,
    autoConnect: false,
  }
);

export default socket;
