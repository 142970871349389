import { Configuration, VoiceroomApi } from "@easygaming/api";
import tokenService from "./token.service";
const basePath = process.env.VUE_APP_API_URL;
export class _VoiceroomService extends VoiceroomApi {
  constructor() {
    super(
      new Configuration({
        basePath: basePath,
      })
    );
    tokenService.reqHandler();

    tokenService.resHandler(basePath);
  }
}

export const VoiceroomService = new _VoiceroomService();
