
import { defineComponent, PropType } from "vue";
import { DataType, TextFieldTypes } from ".";

export default defineComponent({
  name: "TextField",

  props: {
    block: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    hint: {
      type: String as PropType<string | boolean>,
    },
    prepIcon: String,
    icon: String,
    default: Boolean,
    warning: Boolean,
    success: Boolean,
    rules: String,
    value: String,
    autofocus: Boolean,
    error: String,
    onSubmit: Function as PropType<(value: string) => any | Promise<any>>,
    onHintClick: Function as PropType<() => any | Promise<any>>,
    onBlur: Function as PropType<() => any | Promise<any>>,
    onFocus: Function as PropType<() => any | Promise<any>>,
    // iconClick: Function as PropType<
    //   (value: string) => string | Promise<string>
    // >,
    type: {
      type: String as PropType<TextFieldTypes>,
      default: "text",
    },
  },

  data(): DataType {
    return {
      active: false,
      errors: [],
    };
  },
  computed: {
    hasWarn(): any {
      return !this.description && this.rules && !this.error;
    },
    inputVal: {
      get(): string {
        // console.log("get ", this.value);
        return this.value as string;
      },
      set(val: string) {
        // console.log("set ", val);

        this.$emit("input", val);
      },
    },
  },
  methods: {
    onFocusRemove(): void {
      this.active = false;
    },
    onAreaFocus(): void {
      this.active = true;
      if (this.onFocus) this.onFocus();
    },
    onAreaFocusRemove(): void {
      this.active = false;
      if (this.onBlur) this.onBlur();
    },
    submit() {
      if (this.onSubmit) this.onSubmit(this.value as string);
    },
    hintClick() {
      if (this.onHintClick) this.onHintClick();
    },
    keyEnter() {
      this.$emit("keyEnter");
    },
    prepIconClick() {
      this.$emit("prepIconClick");
    },
  },
  mounted() {
    const vm = this;
    setInterval(function () {
      vm.$emit("change");
    }, 200);
    if (this.autofocus) (this.$refs.input as HTMLInputElement).focus();
  },
});
