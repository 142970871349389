<template>
  <a :href="toMap" class="ContactLink">
    <div class="icon">
      <svg
        v-if="type == 'vk'"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.4 3.4C2 4.81333 2 7.07333 2 11.6V12.4C2 16.92 2 19.18 3.4 20.6C4.81333 22 7.07333 22 11.6 22H12.4C16.92 22 19.18 22 20.6 20.6C22 19.1867 22 16.9267 22 12.4V11.6C22 7.08 22 4.82 20.6 3.4C19.1867 2 16.9267 2 12.4 2H11.6C7.08 2 4.82 2 3.4 3.4ZM5.37333 8.08667C5.48 13.2867 8.08 16.4067 12.64 16.4067H12.9067V13.4333C14.58 13.6 15.8467 14.8267 16.3533 16.4067H18.72C18.4773 15.5089 18.0469 14.6727 17.4574 13.9533C16.8679 13.234 16.1326 12.6478 15.3 12.2333C16.0461 11.779 16.6905 11.1756 17.1929 10.461C17.6953 9.7464 18.045 8.93585 18.22 8.08H16.0733C15.6067 9.73334 14.22 11.2333 12.9067 11.3733V8.08667H10.7533V13.8467C9.42 13.5133 7.74 11.9 7.66666 8.08667H5.37333Z"
          fill="currentColor"
        />
      </svg>

      <mdicon size="16" :name="icon" v-else />
    </div>
    <span>
      {{ this.data }}
    </span>
    <div style="margin-left: 6px">
      <mdicon size="16" name="open-in-new" />
    </div>
  </a>
</template>

<script>
export default {
  computed: {
    icon() {
      switch (this.type) {
        case "email":
          return "at";
        case "discord":
          return "discord";
        case "vk":
          return "vk";
        default:
          return "alert";
      }
    },
    toMap() {
      //manual mapping to href
      if (this.to) return this.to;

      switch (this.type) {
        case "email":
          return `to:${this.data}`;
        default:
          return this.data;
      }
    },
  },
  props: {
    type: String,
    data: String,
    to: String,
  },
};
</script>

<style scoped>
.ContactLink {
  display: flex;
  gap: 10px;
  transition: all 0.2s;
  background: unset !important;
  text-decoration: none;
  align-items: center;
  color: var(--color-primary-default);
  border-bottom: unset;
}

.ContactLink:hover {
  color: var(--color-primary-hover);
  border-bottom: unset;
}

.ContactLink:active {
  color: var(--color-primary-active);
}

.ContactLink:hover > span {
  text-decoration: underline;
}

.icon img {
  width: 16px;
  height: 16px;
  fill: red;
  color: red;
}
.icon {
  width: 32px;
  height: 32px;
  display: flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background: var(--cards-upper);
}
</style>
