
import Vue from "vue";
import { PropType } from "vue";
import EZTextField from "@/components/TextField/TextField.vue";

type ValueType = {
  text: string;
  value: string;
  divider?: boolean;
  hidden?: boolean;
  default?: boolean;
  description?: string;
};
type DataType = {
  active: boolean;
  localSearchText: string;
  param: ValueType | null;
  errorText: string;
  savedClearInput: string | number;
};

export default Vue.extend({
  name: "Select",
  data(): DataType {
    return {
      localSearchText: "",
      active: false,
      param: null,
      errorText: "",
      savedClearInput: "",
    };
  },
  props: {
    value: [String, Boolean],
    values: Array as PropType<ValueType[]>,
    block: Boolean,
    required: Boolean,
    clearInput: [String, Number],
    label: String,
    description: String,
    placeholder: String,
    sort: Boolean,
    showHidden: Boolean,
    error: String,
  },

  methods: {
    getParamText(val) {
      let text = val.text;
      if (val.description) text = `${text} (${val.description})`;

      return text;
    },
    handleMenuClick(param: ValueType) {
      this.inputVal = param.value;
      this.searchText = param.text;
      this.active = false;
      this.param = param;
    },

    close(e: MouseEvent) {
      if (e.target && this.$el.contains(e.target as HTMLElement)) return;

      const filteredLabels = this.values?.filter(
        (value) =>
          value.text &&
          value.text.toLowerCase() === this.searchText.toLowerCase()
      )[0];

      if (this.active && this.searchText) {
        if (!filteredLabels) this.errorText = "Вы не указали значение";
      }

      if (this.active && this.required && !this.param) {
        this.errorText = "Это поле необходимо";
      }

      this.active = false;
    },

    handleFocus() {
      this.active = true;
      this.errorText = "";
    },
    handleBlur() {
      this.active = false;
    },
  },
  components: {
    "ez-textfield": EZTextField,
  },

  mounted() {
    this.savedClearInput = new Date().getTime();

    if (this.value)
      this.searchText = this.values.find((val) => val.value == this.value)!
        .text as string;

    document.addEventListener("click", this.close);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  computed: {
    inputVal: {
      get(): string {
        return this.value as string;
      },
      set(val: string) {
        this.$emit("input", val);
        if (!val) this.searchText = "";
      },
    },

    searchText: {
      get(): string {
        return this.localSearchText;
      },
      set(value: string) {
        this.localSearchText = value;
        if (this.param) this.param = null;
      },
    },

    filteredValues: {
      get(): ValueType[] {
        let vals = this.values;
        if (!vals) return [];
        if (!this.searchText && this.showHidden) return this.values;
        if (!this.searchText) return this.values.filter((val) => !val.hidden);

        return vals
          .filter((val) => !val.divider)
          .filter((val) => !val.hidden)
          .sort((a, b) => {
            // if (!this.sort) return 0;
            // if (a.divider || b.divider) return 0;
            if (a.text.toLowerCase().includes(this.searchText.toLowerCase())) {
              if (
                a.text.length < b.text.length &&
                b.text.toLowerCase().includes(this.searchText.toLowerCase())
              )
                return 0;
              else return -1;
            }

            return 1;
          });
      },
    },
  },
  watch: {
    clearInput(val) {
      // this.$emit("input", this.value);
      if (val != this.savedClearInput) {
        this.searchText = "";
        this.savedClearInput = val;
      }
    },
    value(val) {
      if (val) this.$emit("input", val);
      if (val == undefined) this.searchText = "";
      if (val)
        this.searchText = this.values.find((val) => val.value == this.value)!
          .text as string;
    },
    values(vals) {
      const defaultVal = vals.find((val) => val.default);
      if (!this.value && defaultVal) {
        this.handleMenuClick(defaultVal);
      }
    },
  },
});
