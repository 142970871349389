export enum NotificationStyles {
  Primary = "primary",
  Secondary = "secondary",
  Warning = "warning",
  Success = "success",
  Info = "info",
  Error = "error",
}

export enum ButtonDecorations {
  Solid = "solid",
  Outlined = "outlined",
}

export enum ButtonStyles {
  Primary = "primary",
  Secondary = "secondary",
  Warning = "warning",
  Success = "success",
  Info = "info",
  Error = "error",
}
