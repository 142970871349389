
import { defineComponent } from "vue";
import EZCheck from "@/components/Check/Check.vue";
export default defineComponent({
  name: "CheckBox",

  components: {
    "ez-check": EZCheck,
  },

  data() {
    return { showHint: false };
  },

  props: {
    hint: String,
    value: Boolean,
    loading: Boolean,
    disabled: Boolean,
    block: Boolean,
    defaultValue: Boolean,
  },

  methods: {
    click(e) {
      if (e.target.tagName == "A") return;
      if (this.disabled || this.loading) return;

      this.$emit("click");
      this.checked = !this.checked;
    },

    hintClick() {
      this.showHint = !this.showHint;
    },
  },
  mounted() {
    if (this.checked === undefined) this.checked = this.defaultValue || false;
  },
  computed: {
    checked: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value);
      },
    },
  },
});
