import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {});

export default new Vuetify({
  theme: {
    disable: true,
  },
  breakpoint: {
    thresholds: {
      md: 1224,
      lg: 1424,
    },
    mobileBreakpoint: "xs",
  },
  treeShake: true,
});
