<template>
  <div class="d-flex flex-column panel" style="gap: 4px">
    <div style="d-flex flex100" class="createButton mb-2">
      <ez-button primary block @click="createRoom()">Создать канал</ez-button>
    </div>
    <!-- <ez-tag secondary outlined small block> Свернуть каналы</ez-tag> -->
    <VoiceRoomVue
      v-for="room in rooms"
      :key="room._id"
      :room="room"
      @join="joinRoom(room._id)"
    />
    <v-spacer></v-spacer>
    <div v-if="$store.state.room" class="status d-flex mx-n2">
      <div class="d-flex flex-column">
        <p class="m bold">{{ $store.state.room.title }}</p>
        <p class="s">
          {{ voiceStatusText }}
        </p>
      </div>
      <v-spacer></v-spacer>
      <ez-button
        nude
        small
        secondary
        :loading="loading['leave']"
        icon="phone-hangup"
        @click="leaveRoom($store.state.room._id)"
      ></ez-button>
    </div>

    <div class="status d-flex mx-n2">
      <div>
        <ez-img
          style="border-radius: 50%; overflow: hidden; width: 32px; height: 32"
          :src="user.profile.avatar"
          alt="Аватар"
        />
      </div>
      <p class="m bold singleLine ml-2">
        {{ user.displayName }}
      </p>
      <v-spacer></v-spacer>
      <ez-button
        nude
        small
        :secondary="!muted"
        :error="muted"
        :icon="muted ? 'microphone-off' : 'microphone'"
        @click="toggleMute"
      ></ez-button>
      <ez-button
        nude
        small
        :secondary="!deaf"
        :error="deaf"
        :icon="deaf ? 'headphones-off' : 'headphones'"
        @click="toggleDeaf"
      ></ez-button>
      <ez-button nude small icon="cog" secondary to="/settings"></ez-button>
      <audio
        v-for="stream in audioStreams"
        :key="stream.peerId"
        :srcObject.prop="stream.audio"
        controls
        autoplay
        style="display: none"
      ></audio>
    </div>
  </div>
</template>

<script>
import { VoiceroomService } from "@/services/voiceroom.service";
import VoiceRoomVue from "./VoiceRoom.vue";
import Peer from "peerjs";
export default {
  components: { VoiceRoomVue },
  name: "ez-voicepanel",
  computed: {
    user() {
      return this.$store.state.user;
    },
    voiceStatusText() {
      return this.voiceStatus;
    },
  },
  data() {
    return {
      muted: false,
      mutedMain: false,
      deaf: false,
      dialog: null,
      rooms: [],
      peer: null,
      peerId: null,
      mediaStream: null,
      audioStreams: [],
      loading: {},
      voiceStatus: "",
    };
  },
  sockets: {
    roomNewUser(data) {
      const room = this.rooms.find((v) => v._id == data.roomId);
      if (!room) return;
      room.members.push(data.user);
      const audio = new Audio("sounds/user-join.mp3");
      audio.volume = 0.3;
      if (room._id == this.$store.state.room?._id) audio.play();
    },
    roomUserLeft(data) {
      const room = this.rooms.find((v) => v._id == data.roomId);
      if (!room) return;
      const i = room.members.findIndex((v) => v.user._id == data.userId);
      console.log(i, "i");
      if (i > -1) room.members.splice(i, 1);
      const audio = new Audio("sounds/user-leave.mp3");
      audio.volume = 0.3;
      if (room._id == this.$store.state.room?._id) {
        audio.play();
        if (data.userId == this.$store.state.user._id)
          this.$store.commit("saveCurrentRoom", null);
      }
    },
    newRoom(data) {
      this.rooms.push(data);
    },
    deleteRoom(roomId) {
      const r = this.rooms.findIndex((v) => v._id == roomId);
      if (r > -1) {
        this.rooms.splice(r, 1);
        if (roomId == this.$store.state.room?._id) {
          this.$store.commit("saveCurrentRoom", null);
          const peer = this.peer;
          peer.destroy();
          const audio = new Audio("sounds/user-leave.mp3");
          audio.volume = 0.3;
          audio.play();
        }
      }
    },
  },
  methods: {
    toggleMute() {
      this.muted = !this.muted;
      this.mutedMain = !this.mutedMain;
      if (this.muted) {
        const audio = new Audio("sounds/muted.mp3");
        audio.volume = 0.3;
        audio.play();
      } else {
        if (this.deaf) return this.toggleDeaf();
        const audio = new Audio("sounds/non-muted.mp3");
        audio.volume = 0.3;
        audio.play();
      }

      if (this.mediaStream) {
        this.mediaStream.getAudioTracks().forEach((track) => {
          track.enabled = !this.muted; // Disable audio track if muted
        });
      }
    },
    toggleDeaf() {
      this.deaf = !this.deaf;
      if (this.deaf) {
        const audio = new Audio("sounds/deaf.mp3");
        audio.volume = 0.3;
        audio.play();
        this.muted = true;
      } else {
        const audio = new Audio("sounds/non-deaf.mp3");
        audio.volume = 0.3;
        audio.play();
        this.muted = this.mutedMain;
      }

      if (this.mediaStream) {
        this.mediaStream.getAudioTracks().forEach((track) => {
          track.enabled = !this.muted; // Disable audio track if muted
        });
      }
      this.$nextTick(() => {
        // Adjust the volume of all audio elements based on deaf state
        const audios = this.$el.querySelectorAll("audio");
        audios.forEach((audio) => {
          console.log(audio);
          audio.volume = this.deaf ? 0 : 1; // Set volume to 0 if deaf, otherwise 1
        });
      });
    },
    createRoom() {
      const room = {
        title: "Канал " + this.$store.state.user.displayName,
        access: "open",
        maxMembers: 2,
      };
      VoiceroomService.createVoiceroom(room);
    },
    async leaveRoom(id) {
      this.$set(this.loading, "leave", true);
      this.peer?.destroy();

      await VoiceroomService.leaveVoiceroom({ roomId: id }).then(() => {
        console.log("clear 1");
        this.$store.commit("saveCurrentRoom", null);
        console.log(this.peer, "peer");
        // this.peer.disconnect();
      });
      this.$set(this.loading, "leave", false);
    },
    joinRoom(id) {
      if (id == this.$store.state.room?._id) return;
      this.voiceStatus = "Подключение...";
      const vm = this;
      const peerExist = this.peer;
      // const peerUrl = process.env.VUE_APP_API_URL.replace(
      //   "https://",
      //   ""
      // ).replace("http://", "");
      // const peerUrl = "localhost";
      const peerUrl = "test.easygaming.su";
      const peer = new Peer({
        host: peerUrl,
        port: 443,
        path: "/peer",
      });

      if (peerExist) {
        console.log("destroy peerEx");
        peerExist.destroy();
      }

      peer.on("open", function (peerId) {
        vm.voiceStatus = "Peer open";
        console.log("My peer ID is: " + peerId);
        vm.peerId = peerId;

        VoiceroomService.joinVoiceroom({
          roomId: id,
          value: peerId,
        })
          .then((res) => {
            vm.peer = peer;
            const room = res.data;
            console.log(room, "joined at room");
            vm.$store.commit("saveCurrentRoom", room);
            const audio = new Audio("sounds/user-join.mp3");
            audio.volume = 0.3;
            audio.play();
            vm.voiceStatus = "Подключено";
            if (!room) return;
            room.members.forEach((member) => {
              if (member.user._id == vm.$store.state.user._id) return;
              console.log("Calling ", member);

              var call = vm.peer.call(member.peerId, vm.mediaStream);
              call.on("stream", function (stream) {
                console.log("Call accepted");
                const auI = vm.audioStreams.findIndex(
                  (v) => v.peerId == member.peerId
                );
                if (auI > -1) vm.audioStreams.splice(auI, 1);
                vm.audioStreams.push({
                  audio: stream,
                  peerId: member.peerId,
                });
              });
              call.on("close", () => {
                console.log("Call closed with ", member);
              });
              call.on("error", (err) => {
                console.log("Call error ", err, member);
              });
            });
          })
          .catch((e) => {
            console.log(e, "eee");
            //peer.destroy();
          });
      });

      peer.on("call", function (call) {
        // Answer the call, providing our mediaStream
        console.log("J Call incoming");
        call.answer(vm.mediaStream);
        call.on("stream", (stream) => {
          // Show stream in some <video> element.
          console.log("J Call accepted");
          const auI = vm.audioStreams.findIndex((v) => v.peerId == call.peer);
          if (auI > -1) vm.audioStreams.splice(auI, 1);
          vm.audioStreams.push({ audio: stream, peerId: call.peer });
        });
        call.on("close", () => {
          console.log("Call closed with ");
        });
        call.on("error", (err) => {
          console.log("Call error ", err);
        });
      });

      peer.on("close", () => {
        console.log("Peer closed");
      });

      peer.on("disconnected", () => {
        console.log("Peer disconnected ");

        vm.$nextTick(() => {
          if (peer.destroyed) return;
          console.log("Peer reconnect...");
          peer.reconnect();
        });
      });

      peer.on("destroyed", () => {
        console.log("Peer destroyed ");
        //peer.reconnect();
      });

      peer.on("error", (err) => {
        console.log("Peer error -", err.type);
      });

      peer.on("data", (data) => {
        console.log("data: ", data);
      });
    },
  },
  async mounted() {
    const vc = await VoiceroomService.getActualVoicerooms();
    this.rooms = vc.data;

    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true, // Автоматическая регулировка усиления для чёткого голоса
        sampleRate: 48000, // Частота дискретизации для улучшения качества
      },
    });

    this.mediaStream = stream;
    this.mediaStream.getAudioTracks().forEach((track) => {
      console.log(track, "track");
      track.enabled = !this.muted; // Apply mute state on stream initialization
    });
    const room = this.$store.state.room;

    if (room) {
      await this.leaveRoom(room._id);
      console.log("join", room._id);
      this.joinRoom(room._id);
    }
  },
};
</script>

<style scoped>
.panel {
  height: 100%;
  padding: 0 8px;
  background: var(--backgrounds-upper);
  box-shadow: 0px 24px 10px 0px rgba(0, 45, 75, 0),
    0px 14px 8px 0px rgba(0, 45, 75, 0.02),
    0px 6px 6px 0px rgba(0, 45, 75, 0.03), 0px 2px 3px 0px rgba(0, 45, 75, 0.03);
  border-radius: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  position: sticky;
  top: 88px;
  /* border: 1px solid var(--cards-cards-darker); */
}

.createButton {
  z-index: 2;
  top: 0px;
  padding-top: 8px;
  position: sticky;
  background: var(--backgrounds-upper);
}

.status {
  position: sticky;
  bottom: 0;
  background: var(--backgrounds-superupper);
  padding: 8px 8px;
  align-items: center;
}
</style>
