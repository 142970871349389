<template>
  <div v-if="room" class="d-flex flex-column room" style="gap: 2px">
    <div
      class="d-flex align-center title"
      :style="{ color: 'var(--texts-sub-title)' }"
      @click="$emit('join')"
    >
      <div
        :style="{
          color: iAmOwner
            ? 'var(--color-success-default)'
            : 'var(--texts-sub-title)',
        }"
      >
        <mdicon :name="iAmInRoom ? 'volume-high' : 'volume-low'" size="20" />
      </div>
      <p class="s bold ml-1">{{ room.title }}</p>
      <v-spacer></v-spacer>
      <ez-button
        v-if="iAmOwner"
        nude
        small
        icon="delete"
        error
        @click.stop="deleteRoom()"
      ></ez-button>
      <ez-button
        v-if="iAmOwner"
        nude
        small
        icon="cog"
        secondary
        @click.stop=""
      ></ez-button>
      <ez-button
        nude
        small
        icon="account-plus"
        @click.stop=""
        secondary
      ></ez-button>
    </div>
    <div class="members d-flex flex-column">
      <VoiceMember
        icon="star"
        v-for="member in room.members"
        :key="member._id"
        :member="member"
      />
    </div>
  </div>
</template>

<script>
import { VoiceroomService } from "@/services/voiceroom.service";

import VoiceMember from "./VoiceMember.vue";

export default {
  components: { VoiceMember },
  name: "ez-voiceroom",
  props: {
    room: Object,
  },
  data() {
    return {};
  },
  methods: {
    async deleteRoom() {
      await VoiceroomService.deleteVoiceroom({
        roomId: this.room._id,
      });
    },
  },
  computed: {
    iAmInRoom() {
      const u = this.$store.state.user;
      if (this.room?.members?.find((v) => v.user._id == u._id)) return true;
      return false;
    },
    iAmOwner() {
      const u = this.$store.state.user;
      if (this.room?.owner == u._id) return true;
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.room {
  background: var(--cards-card);
  border-radius: 4px;
}
.members {
  padding: 4px 4px 4px 28px;
  gap: 2px;
}

.title {
  cursor: pointer;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background: var(--cards-upper-hover);
    .__ez-button {
      display: inline-flex;
    }
  }

  &:active {
    background: var(--cards-upper-active);
  }

  .__ez-button {
    display: none;
  }
}
</style>
