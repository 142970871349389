
import { defineComponent } from "vue";
export default defineComponent({
  name: "UserList",

  props: {
    loading: Boolean,
    disabled: Boolean,
    user: Object,
    icon: String,
    iconColor: String,
    muted: Boolean,
    text: String,
  },
  methods: {
    click() {
      if (!this.disabled) this.$emit("click");
    },
  },
});
