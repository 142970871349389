
import { defineComponent } from "vue";
import { NotificationStyles } from ".";

import EZButton from "@/components/Button/Button.vue";

type DataType = {
  style: NotificationStyles;
  buttonStyles: Record<
    "primary" | "secondary" | "warning" | "error" | "info" | "success",
    boolean
  >;
};

export default defineComponent({
  name: "Notification",
  data(): DataType {
    return {
      style: NotificationStyles.Primary,
      buttonStyles: {
        error: this.error,
        primary: this.primary,
        info: this.info,
        success: this.success,
        secondary: this.secondary,
        warning: this.warning,
      },
    };
  },
  components: {
    "ez-button": EZButton,
  },
  props: {
    primary: Boolean,
    success: Boolean,
    error: Boolean,
    warning: Boolean,
    info: Boolean,
    secondary: Boolean,

    title: String,
    text: String,
    footer: String,

    light: Boolean,
    close: Boolean,
    timed: Boolean,
    icon: String,

    buttonA: String,
    buttonB: String,

    actionA: Function,
    actionB: Function,
  },

  methods: {
    closeNotification() {
      this.$emit("closeNotification");
    },
    getButtonStyle(): string {
      if (this.success) return NotificationStyles.Success;
      if (this.error) return NotificationStyles.Error;
      if (this.warning) return NotificationStyles.Warning;
      if (this.info) return NotificationStyles.Info;
      if (this.secondary) return NotificationStyles.Secondary;
      return NotificationStyles.Primary;
    },

    getButtonDecoration() {
      const data = [] as string[];

      if (this.light) data.push("light");
      else data.push("solid");

      return data.join(" ");
    },

    getNotifClassnames(): string {
      return `${this.getButtonDecoration()} ${this.getButtonStyle()}`;
    },
  },
});
