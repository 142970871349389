<template>
  <v-tooltip top nudge-top="-11">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="on" />
      </div>
    </template>
    <div class="tooltip_wrapper">
      <div class="tooltip">
        <slot name="content" />
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="6"
        viewBox="0 0 12 6"
        fill="none"
      >
        <path
          d="M6 6L0 0L12 6.05682e-07L6 6Z"
          fill="var(--backgrounds-upper)"
        />
      </svg>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "ez-tooltip",
};
</script>

<style scoped>
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
.v-tooltip__content {
  background: unset;
  padding: unset !important;
  color: unset !important;
}

.tooltip_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tooltip {
  color: var(--texts-paragraph);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: var(--backgrounds-upper);
  padding: 8px 16px;
  border-radius: 6px;
  box-shadow: 0px 3px 7px 0px rgba(0, 45, 75, 0.06),
    0px 12px 12px 0px rgba(0, 45, 75, 0.05),
    0px 27px 16px 0px rgba(0, 45, 75, 0.03),
    0px 49px 20px 0px rgba(0, 45, 75, 0.01);
}
</style>
