import { Configuration, LobbyApi } from "@easygaming/api";
import tokenService from "./token.service";
const basePath = process.env.VUE_APP_API_URL;

export class _LobbyService extends LobbyApi {
  constructor() {
    super(
      new Configuration({
        basePath: basePath,
      })
    );
    tokenService.reqHandler();

    tokenService.resHandler(basePath);
  }
}

export const LobbyService = new _LobbyService();
