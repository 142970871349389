
import Vue from "vue";

export default Vue.extend({
  name: "Dialog",
  props: {
    value: [Boolean, String],
    dialogName: String,
    title: String,
  },
  data() {
    return {
      mounted: false,
      open: false,
    };
  },
  methods: {
    tryClose(el) {
      if (el.classList.contains("__ez-dialog-handler")) this.close();
    },
    close(): void {
      if (!this.open) return;
      if (!this.mounted) return;
      if (typeof this.value == "boolean") this.inputVal = false;
      else this.inputVal = "none";
      this.$emit("cancel");
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      window.scrollTo(0, this.$store.state.scrollTop);
      this.open = false;
    },
    accept() {
      this.$emit("accept");
    },
    render() {
      this.$store.state.scrollTop = window.scrollY;

      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.style.top = `-${this.$store.state.scrollTop}px`;
      this.open = true;
    },
  },
  computed: {
    inputVal: {
      get(): boolean | string {
        if (!this.value || this.value == "none") this.close();

        if (
          (this.value && this.value == this.dialogName) ||
          this.value === true
        )
          this.render();
        if (typeof this.value == "boolean") return this.value;

        return this.value === this.dialogName;
      },
      set(val: boolean | string): void {
        this.$emit("input", val);
      },
    },
  },

  created() {
    const vm = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.key == "Escape") {
        vm.close();
      }
    });
    this.mounted = true;
  },
});
