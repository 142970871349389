
import EZTag from "@/components/Tag/Tag.vue";
import { ThemeController, ThemesList } from "@/themes";

import Vue from "vue";
export default Vue.extend({
  components: {
    "ez-tag": EZTag,
  },

  methods: {
    changeToLight() {
      ThemeController.loadDefaultTheme(ThemesList.Light);
    },
    changeToDark() {
      ThemeController.loadDefaultTheme(ThemesList.Dark);
    },
  },

  data() {
    return {
      loading: false,
    };
  },
});
