
import Vue, { PropType } from "vue";
import Loader from "@/components/Loader/Loader.vue";

export default Vue.extend({
  name: "Check",

  components: {
    "ez-loader": Loader,
  },

  props: {
    loading: Boolean,
    value: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    checked: {
      type: Boolean as PropType<boolean | undefined>,
      required: false,
      default: undefined,
    },
    disabled: Boolean,
    tabindex: {
      type: Number as PropType<number | null>,
      default: 0,
    },
  },

  methods: {
    click() {
      if (this.loading || this.disabled) return;
      this.$emit("click");
      if (this.value != undefined) this.localChecked = !this.localChecked;
    },
  },
  computed: {
    localChecked: {
      set(value: boolean) {
        this.$emit("input", value);
      },
      get(): boolean {
        if (typeof this.checked === "boolean" && this.value === undefined) {
          return this.checked;
        }

        return this.value ?? (this.checked || false);
      },
    },
  },
});
