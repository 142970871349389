<template>
  <div class="__ez-voicemember">
    <div>
      <ez-img
        style="border-radius: 50%; overflow: hidden; width: 18px; height: 18px"
        :src="user.profile.avatar"
        alt="Аватар"
      />
    </div>
    <p class="s ml-1 singleLine">
      {{ user.displayName }}
    </p>

    <v-spacer></v-spacer>

    <div v-if="icon" :style="{ color: 'var(--texts-sub-title)' }">
      <mdicon :name="icon" size="16"> </mdicon>
    </div>

    <div v-if="muted" :style="{ color: 'var(--color-error-default)' }">
      <mdicon name="microphone-off"> </mdicon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ez-voicemember",
  props: {
    icon: String,
    muted: Boolean,
    member: Object,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.member.user;
    },
  },
};
</script>

<style scoped lang="scss">
.__ez-voicemember {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  background: var(--cards-upper);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--cards-upper-hover);
  }

  &:active {
    background: var(--cards-upper-active);
  }
}
</style>
