export enum TagDecorations {
  Solid = "solid",
  Nude = "nude",
  Outlined = "outlined",
}
export enum TagStyles {
  Primary = "primary",
  Secondary = "secondary",
  Warning = "warning",
  Success = "success",
  Custom = "custom",
  Error = "error",
}

export enum TagLoaderColors {
  Primary = "var(--color-primary-default)",
  Secondary = "var(--color-secondary-default)",
  Warning = "var(--color-warning-default)",
  Success = "var(--color-success-default)",
  Custom = "var(--color-info-default",
  Error = "var(--color-error-default)",
}
