import { Configuration, AuthApi } from "@easygaming/api";
import tokenService from "./token.service";
const basePath = process.env.VUE_APP_API_URL;
export class _AuthService extends AuthApi {
  constructor() {
    super(
      new Configuration({
        basePath: basePath,
      })
    );
    tokenService.reqHandler();

    tokenService.resHandler(basePath);
  }
}

export const AuthService = new _AuthService();
