
import EZButton from "@/components/Button/Button.vue";
import EZMenuButton from "@/components/MenuButton/MenuButton.vue";
import { ThemeController, ThemesList } from "@/themes";

import Vue from "vue";
export default Vue.extend({
  components: {
    "ez-button": EZButton,
    "ez-menubutton": EZMenuButton,
  },

  data() {
    return {
      loading: false,
    };
  },
  methods: {
    changeToLight() {
      ThemeController.loadDefaultTheme(ThemesList.Light);
    },
    changeToDark() {
      ThemeController.loadDefaultTheme(ThemesList.Dark);
    },

    changeToCustom() {
      ThemeController.loadCustomTheme(
        "https://cdn.discordapp.com/attachments/535161963827953705/1155432840691994724/custom.css"
      );
    },
  },
});
