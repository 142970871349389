import { ThemesList, VariablesList } from "./types";

export class _ThemeController {
  private readonly linkId = "__ez-theme-data";
  private currentThemeUrl: string | null = null;
  private defaultDevPath = "/themes/";

  constructor() {
    const theme = localStorage.getItem("theme") || "dark"; // Здесь будет запрос темы из кукки или локал стораджа

    this.renderTheme(theme);
  }

  loadDefaultTheme(name: ThemesList) {
    return this.renderTheme(name);
  }

  loadCustomTheme(name: string) {
    return this.renderTheme(name);
  }

  private renderTheme(name: string) {
    return new Promise<void>((resolve) => {
      const url = this.getThemeUrl(name);

      const oldLinkElement = document.querySelector(
        `#${this.linkId}`
      ) as HTMLLinkElement;

      const link = this.createElement();
      link.rel = "stylesheet";
      link.href = url;
      link.id = this.linkId;

      this.currentThemeUrl = url;

      link.onload = () => {
        if (oldLinkElement) oldLinkElement.remove();
        // eslint-disable-next-line
        console.log(`Установлена тема: ${this.getThemeName(url)}`);
        resolve();
      };
      document.head.appendChild(link);
    });
  }

  private createElement(): HTMLLinkElement {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.id = this.linkId;

    return link;
  }

  getThemeUrl(name: string) {
    const isUrlRegex = /(https?:\/\/[^\s]+)/g;
    const isUrl = isUrlRegex.test(name);

    if (!isUrl) {
      let path = this.defaultDevPath + name;
      if (!path.endsWith(".css")) path += ".css";

      return path;
    }

    return name;
  }

  getThemeName(url: string) {
    const splittedUrl = url.split(/\\+|\/+/);
    return splittedUrl[splittedUrl.length - 1].replace(".css", "");
  }

  getColor<Name extends keyof VariablesList = keyof VariablesList>(name: Name) {
    return getComputedStyle(document.documentElement).getPropertyValue(
      `--${name}`
    );
  }
}

export const ThemeController = new _ThemeController();

export * from "./types";
