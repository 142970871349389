
import { defineComponent } from "vue";
import { TagStyles, TagDecorations } from ".";

export default defineComponent({
  name: "Tag",

  props: {
    solid: Boolean,
    outlined: Boolean,
    nude: Boolean,

    primary: Boolean,
    secondary: Boolean,
    warning: Boolean,
    error: Boolean,
    success: Boolean,

    icon: String,
    content: String,
    plus: Boolean,
    close: Boolean,

    loading: Boolean,
    block: Boolean,
    disabled: Boolean,
    hover: Boolean,
  },

  methods: {
    getTagStyle(): string {
      if (this.primary) return TagStyles.Primary;
      if (this.secondary) return TagStyles.Secondary;
      if (this.warning) return TagStyles.Warning;
      if (this.success) return TagStyles.Success;
      if (this.error) return TagStyles.Error;

      return TagStyles.Primary;
    },

    getTagDecoration(): string {
      if (this.solid) return TagDecorations.Solid;
      if (this.outlined) return TagDecorations.Outlined;
      if (this.nude) return TagDecorations.Nude;

      return TagDecorations.Solid;
    },

    onClick() {
      if (!this.loading) this.$emit("click");
    },
  },

  computed: {
    tagClasses(): string {
      const result = [this.getTagDecoration(), this.getTagStyle()];

      if (this.block) result.push("block");
      if (this.hover) result.push("hover");
      return result.join(" ");
    },
  },
});
