
import { LobbyService } from "@/services/lobby.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LobbyCard",
  props: {
    lobby: Object,
  },

  data() {
    return {
      tags: [String],
      discordDialog: false,
    };
  },
  computed: {
    totalPlayers() {
      const lobby = (this as any).lobby;
      if (!lobby) return 0;

      // if (!lobby.players || !lobby.players[0])
      //   return `0 / ${lobby.maxPlayers || 0}`;
      if (lobby.expired <= 0) {
        return `${lobby.players?.length + lobby.dsPlayers?.length} / ${
          lobby.maxPlayers
        }`; //(+" / " + lobby.maxPlayers || 0);
      } else {
        return lobby.playersAll?.length + lobby.dsPlayersAll?.length;
      }
    },
    allTags(): string[] {
      let tags = [] as string[]; //было this.lobby!.tags || [], строки дальше не было
      tags = tags.concat(this.lobby!.tags || []);

      const modify =
        this.lobby!.game?.teammaterModify || this.lobby!.game?.modify;
      if (!modify) return tags;
      const category = modify
        .find((val: { field: string }) => val.field == "category")
        .values.find(
          (val: { text: string; value: string }) =>
            val.value == this.lobby!.category
        );
      if (!category) return tags;

      const mode = category.modify.values.find(
        (val: { text: string; value: string }) =>
          val.value && val.value == this.lobby!.mode
      );

      if (mode && tags.indexOf(mode.text) == -1) tags.push(mode.text);
      if (category && tags.indexOf(category.text) == -1)
        tags.push(category.text);
      tags.reverse();
      return tags;
    },
  },
  methods: {
    toLobby() {
      if (!this.lobby!._id) return;
      if (this.lobby!.expired > 0) return;
      if (!this.lobby!.small)
        return this.$router.push("/lobby/" + this.lobby!._id);
      if (this.lobby!.expired > 0 && this.lobby!.small) return;
      else if (!this.discordDialog) this.discordDialog = true;
    },
    moveMe() {
      LobbyService.editLobby({
        setting: "moveMe",
        value: "none",
        lobbyId: this.lobby!._id,
      })
        // .then(() => {
        //   setTimeout(() => {
        //     return this.$notify({
        //       data: {
        //         type: "error",
        //         title: "Что-то пошло не так",
        //         text: "Попробуйте ещё раз или присоединитесь к каналу Discord напрямую",
        //         icon: "close-octagon",
        //       },
        //     });
        //   }, 5000);
        // })
        .catch((err) => {
          return this.$notify({
            data: {
              type: "error",
              title: "Нельзя присоединиться",
              text: err.response.data.message,
              icon: "close-octagon",
            },
          });
        });
    },
  },
});
