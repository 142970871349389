
import { defineComponent, VNode } from "vue";
import { ButtonStyles, ButtonDecorations, ButtonLoaderColors } from ".";
import EZLoader from "@/components/Loader/Loader.vue";

type DataType = {
  decoration: ButtonDecorations;
  style: ButtonStyles;
};

export default defineComponent({
  name: "Button",

  components: {
    "ez-loader": EZLoader,
  },

  data(): DataType {
    return {
      decoration: ButtonDecorations.Solid,
      style: ButtonStyles.Primary,
    };
  },

  props: {
    primary: Boolean,
    secondary: Boolean,
    warning: Boolean,
    success: Boolean,
    info: Boolean,
    error: Boolean,

    solid: Boolean,
    nude: Boolean,
    outlined: Boolean,
    tab: Boolean,

    showText: {
      type: Boolean,
      default: true,
    },
    dHelp: String,

    to: String,
    noBlank: Boolean,

    disabled: Boolean,
    loading: Boolean,
    block: Boolean,
    icon: String,
    active: Boolean,
    large: Boolean,
    left: Boolean,
    counter: Number,
    small: Boolean,
  },

  methods: {
    getButtonStyle(): string {
      if (this.primary) return ButtonStyles.Primary;
      if (this.secondary) return ButtonStyles.Secondary;
      if (this.warning) return ButtonStyles.Warning;
      if (this.success) return ButtonStyles.Success;
      if (this.info) return ButtonStyles.Info;
      if (this.error) return ButtonStyles.Error;

      return ButtonStyles.Primary;
    },

    getButtonDecoration(): string {
      if (this.solid) {
        this.decoration = ButtonDecorations.Solid;
        return ButtonDecorations.Solid;
      }
      if (this.nude) {
        this.decoration = ButtonDecorations.Nude;
        return ButtonDecorations.Nude;
      }
      if (this.outlined) {
        this.decoration = ButtonDecorations.Outlined;
        return ButtonDecorations.Outlined;
      }

      if (this.tab) {
        this.decoration = ButtonDecorations.Tab;
        return ButtonDecorations.Tab;
      }

      return ButtonDecorations.Solid;
    },
    click(event) {
      if (this.loading) event.stopPropagation();
    },
  },
  computed: {
    getButtonClassnames(): string {
      let result = [
        `${this.getButtonDecoration()}`,
        `${this.getButtonStyle()}`,
      ];

      if (this.block) result.push("block");
      if (this.active) result.push("active");
      if (this.loading) result.push("loading");
      if (this.left) result.push("left");
      if (this.to) result.push("link");
      if (this.small) result.push("small");
      return result.join(" ");
    },

    loaderColor() {
      if (this.decoration === ButtonDecorations.Solid) return "white";

      if (this.primary) return ButtonLoaderColors.Primary;
      if (this.secondary) return ButtonLoaderColors.Secondary;
      if (this.warning) return ButtonLoaderColors.Warning;
      if (this.success) return ButtonLoaderColors.Success;
      if (this.info) return ButtonLoaderColors.Info;
      if (this.error) return ButtonLoaderColors.Error;

      return "white";
    },

    content(): VNode {
      return (this.$slots.default || [])[0];
    },
  },
});
