
import { RuleType } from "@/components/Input";

import { ThemeController } from "@/themes";
type DataType = {
  rules: RuleType[];
  value: string;
  visible: boolean;
  password: string;
  textfield: string;
};

import { defineComponent } from "vue";
export default defineComponent({
  name: "InputsList",

  data(): DataType {
    return {
      rules: [(value) => !!value || "Вы не указали значение"],
      value: "",
      visible: false,
      password: "",
      textfield: "",
    };
  },
  methods: {
    changeTheme(theme: string) {
      ThemeController.loadCustomTheme(theme);
    },
  },
});
