import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import HomeView from "../views/Home.vue";
import ButtonsList from "../views/ButtonsList.vue";
import InputsList from "../views/InputsList.vue";
import TagsList from "@/views/TagsList.vue";
import store from "@/store";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/buttons",
    name: "buttons",
    component: ButtonsList,
  },
  {
    path: "/tags",
    name: "tags",
    component: TagsList,
  },
  {
    path: "/inputs",
    name: "inputs",
    component: InputsList,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/NotificationList.vue"),
  },
  {
    path: "/elements",
    name: "Elements",
    component: () => import("../views/Elements.vue"),
  },
  {
    path: "/switch",
    name: "Switch",
    component: () => import("../views/SwitchList.vue"),
  },
  {
    path: "/check",
    name: "Check",
    component: () => import("../views/CheckList.vue"),
  },
  {
    path: "/sign",
    name: "Sign",
    component: () => import("../views/Sign.vue"),
    meta: {
      noMenu: true,
      title: "Регистрация и Вход",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      title: "Профиль",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      title: "Настройки",
    },
  },
  {
    path: "/leftmenu",
    name: "LeftMenuList",
    component: () => import("../views/LeftMenuList.vue"),
  },
  {
    path: "/xenix-test",
    name: "блять",
    component: () => import("../views/xenix-test.vue"),
  },
  {
    path: "/userslist",
    name: "UsersList",
    component: () => import("../views/UsersList.vue"),
  },
  {
    path: "/selectlist",
    name: "SelectList",
    component: () => import("../views/SelectList.vue"),
    meta: {
      noMenu: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/lobby",
    name: "Lobbies",
    component: () => import("../views/Lobbies.vue"),
  },
  {
    path: "/lobby/create",
    name: "LobbyCreate",
    component: () => import("../views/LobbyCreate.vue"),
    meta: {
      noMenu: true,
      title: "Создание лобби",
    },
  },
  {
    path: "/lobby/:id",
    name: "Lobby",
    component: () => import("../views/Lobby.vue"),
    meta: {
      noMenu: true,
      title: " ",
    },
  },
  {
    path: "/*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
    // meta: {
    //   noMenu: true,
    // },
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/", "/sign", "/lobby", "/lobby/create"];
  const adminPages = ["/admin", "/xenix-test"];

  const authRequired =
    !publicPages.includes(to.path) &&
    !publicPages.includes(to.path.split("/")[1]);
  const adminRequired =
    adminPages.includes(to.path) || adminPages.includes(to.path.split("/")[1]);

  if (to.path == "/sign" && store.state.user)
    //&& store.state.user
    return router.push({ name: "Home" });
  if (!store.state.user && authRequired) {
    // console.log("TO SIGN", to.fullPath);
    // console.log(to.path, "aaaaaaa");
    store.commit("setUnAuthPath", to.fullPath);
    return;
    // next({ name: "Sign", params: { from: to.fullPath } });
  }
  if (adminRequired && !(store.state.user as any)?.roles.includes("admin")) {
    next("/");
  } else {
    next();
  }
  // window.scrollTo(0, 0);
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta?.title) document.title = "Easy Gaming - " + to.meta.title;
    else document.title = "Easy Gaming - Поиск Игроков";
  });
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(
  location: RawLocation
): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated error
        if (error.name === "NavigationDuplicated") {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(
  location: RawLocation
): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalReplace.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // on abort

        // only ignore NavigationDuplicated error
        if (error.name === "NavigationDuplicated") {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

export default router;
