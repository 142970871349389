
import Vue from "vue";
// import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import SwipeModal from "@takuma-ru/vue-swipe-modal";
export default Vue.extend({
  name: "Float",
  components: {
    SwipeModal,
  },
  props: {
    value: [Boolean, String],
    id: String,
    closeOnClick: Boolean,
  },
  data() {
    return {
      floaterOpen: false,
      opening: false,
      isOpen: false,
      openOk: false,
      backgroundColor: "#aaaaaaaa",
    };
  },
  methods: {
    getTheme() {
      const theme = localStorage.getItem("theme");
      return theme;
    },
    close() {
      // if (this.closeOnClick) this.closeFloater();
      const bg = document.getElementsByClassName("modal-background")[0];
      if (!bg) this.closeFloater();
      if (this.openOk) {
        bg?.dispatchEvent(new MouseEvent("click"));
      }
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    async openFloater(user: any, e: any) {
      this.$emit("open");
      //e.stopPropagation();
      // if (this.$store.state.isMobile) {
      //   this.openOk = false;
      //   const el = document.querySelector("#SwipeModal");
      //   const parent = document.querySelector("#app");

      //   (parent as any).appendChild(el as any);

      //   const styles = getComputedStyle(document.documentElement);
      //   const bgColor = styles.getPropertyValue("--cards-cards");
      //   this.backgroundColor = bgColor + "80";
      //   this.$nextTick(() => {
      //     this.isOpen = true;
      //   });

      //   setInterval(() => {
      //     this.openOk = true;
      //   });

      //   return;
      // }

      this.floaterOpen = true;
      this.opening = true;
      await this.$nextTick();
      var activator = (
        this.$refs[`activator${this.id}`] as HTMLDivElement
      ).getBoundingClientRect();
      let modDocument = this.$refs[`floater${this.id}`] as HTMLDivElement;
      // console.log(e, "y");
      // console.log(`${e.pageY - activator.height}px`, `${e.clientX + 24}px`);
      await this.$nextTick();
      modDocument.style.top = `${e.pageY - activator.height}px`;
      modDocument.style.left = `${e.clientX + 24}px`;
      //////

      await this.$nextTick();
      var floater = (
        this.$refs[`floater${this.id}`] as HTMLDivElement
      ).getBoundingClientRect();
      var difY = e.clientY + floater.height - window.innerHeight;
      // console.log(e.clientX, floater.width, window.innerWidth);
      var difX = e.clientX + floater.width - window.innerWidth;
      await this.$nextTick();
      // console.log(difX, "difX");
      setTimeout(() => {
        this.opening = false;
      }, 100);

      if (difY >= -32) {
        modDocument.style.top = `${
          (modDocument.style.top.replace("px", "") as unknown as number) -
          difY -
          32
        }px`;
      }

      if (difX > -40) {
        // console.log("toleft", modDocument.style.left);
        modDocument.style.left = `${
          (modDocument.style.left.replace("px", "") as unknown as number) -
          difX -
          48
        }px`;
      }
    },
    async closeFloater() {
      if (this.opening) return;
      console.log("close");

      this.floaterOpen = false;
      this.opening = false;
    },
  },
  created() {
    const styles = getComputedStyle(document.documentElement);
    const bgColor = styles.getPropertyValue("--cards-cards");
    this.backgroundColor = bgColor + "80";
  },
  computed: {
    // inputVal: {
    //   get(): boolean | string {
    //     if (typeof this.value == "boolean") return this.value;
    //     return this.value === this.dialogName;
    //   },
    //   set(val: boolean | string): void {
    //     this.$emit("input", val);
    //   },
    // },
  },
});
