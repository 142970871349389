import { render, staticRenderFns } from "./VoiceMember.vue?vue&type=template&id=270cb1fa&scoped=true"
import script from "./VoiceMember.vue?vue&type=script&lang=js"
export * from "./VoiceMember.vue?vue&type=script&lang=js"
import style0 from "./VoiceMember.vue?vue&type=style&index=0&id=270cb1fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270cb1fa",
  null
  
)

export default component.exports