
import { defineComponent } from "vue";
import EZSwitch from "@/components/Switch/Swtich.vue";

export default defineComponent({
  name: "SwitchBox",

  components: {
    "ez-switch": EZSwitch,
  },

  data() {
    return {
      showHint: false,
    };
  },

  props: {
    hint: String,
    value: Boolean,
    loading: Boolean,
    disabled: Boolean,
    block: Boolean,
    prevent: Boolean,
  },

  methods: {
    click() {
      if (this.disabled || this.loading) return;
      this.$emit("click");
      this.checked = !this.checked;
    },
    hintClick(e: MouseEvent) {
      e.stopImmediatePropagation();
      this.showHint = !this.showHint;
    },

    onFocus() {
      if (!this.showHint) this.showHint = true;
      else this.showHint = false;
    },
    onBlur() {
      this.showHint = false;
    },
  },

  computed: {
    checked: {
      get(): boolean {
        // if (this.value != undefined) return this.value;

        return this.value;
      },
      set(value: boolean) {
        if (this.prevent) return;
        this.$emit("input", value);
      },
    },
  },
});
