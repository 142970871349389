
import { defineComponent, PropType } from "vue";
import { DataType, TextFieldTypes } from ".";

export default defineComponent({
  name: "Input",
  props: {
    block: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    disabled: {
      type: Boolean,
    },
    vRules: String,
    hint: {
      type: String as PropType<string | boolean>,
    },
    default: Boolean,
    warning: Boolean,
    success: Boolean,
    required: Boolean,
    onSubmit: Function as PropType<(value: string) => any | Promise<any>>,
    onHintClick: Function as PropType<() => any | Promise<any>>,
    onInput: Function as PropType<(value: string) => any | Promise<any>>,
    onFocus: Function as PropType<() => any | Promise<any>>,
    onBlur: Function as PropType<() => any | Promise<any>>,
    icon: String,
    value: String,
    type: {
      type: String as PropType<TextFieldTypes>,
      default: "text",
    },
  },
  data(): DataType {
    return {
      active: false,
      warningMessage: null,
    };
  },
  methods: {
    onAreaFocus(): void {
      this.active = true;
      if (this.onFocus) this.onFocus();
    },
    onAreaFocusRemove(): void {
      this.active = false;
      if (this.onBlur) this.onBlur();
    },
    submit() {
      if (this.onSubmit) this.onSubmit(this.inputVal as string);
    },
    hintClick() {
      if (this.onHintClick) this.onHintClick();
    },
  },

  computed: {
    inputVal: {
      get(): string {
        return this.value as string;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },
  },
});
